
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.max-h-0 {
  max-height: 0;
  overflow: hidden;
}

.max-h-full {
  max-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}






.slide .img {
  overflow-y: hidden;

  padding: 3;
  /* width: 500px; */

  /* margin: 0 auto; */
}

.slide {
  overflow-y: hidden;
  border-radius: 25px;
  transform: scale(0.75);
  transition: transform 300ms;
  
  
  /* margin: 0 10px; Adjust the margin as per your requirement */
}

.slide .img {
  border-radius: 25px;
}

.activeSlide {
  overflow-y: hidden;
  transform: scale(1.1);
  opacity: 1;
  padding: 3% 0;
  border-radius: 25px;
  /* margin: 0 40px; Adjust the margin as per your requirement */
}

.activeSlide .img {
  border-radius: 25px;
  /* Adjust the value as per your preference */
}

.arrow {
  /* background-color: #000; */
  position: absolute;
  cursor: pointer;
  z-index: 10;
  /* opacity: 70%; */
}

.arrow svg {
  /* transition: color 300ms; */
}

.arrow svg:hover {
  color: #68edff;
}

/* .card:hover .intro{
  height: 220px;
  bottom: 240px;
} */


/* Global CSS for List Styling */
ul {
  list-style-type: disc; 
  padding-left: 20px; 
}

li {
  margin-bottom: 8px; 
}
